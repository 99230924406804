<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="loyaltyHistories"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullname)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'admin-users-edit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.title }}
            </b-link>
            <small class="text-muted">{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <!-- <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.roles)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.roles)}`"
            />
            <span class="align-text-top text-capitalize">{{ resolveUserRolesArray(data.item.roles) }}</span>
          </div>
        </template> -->

        <!-- Column: Status -->
        <!-- <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
          {{ resolveUserStatusVariantText(data.item.status) }}

          </b-badge>
        </template> -->

        <!-- Column: Actions -->

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BMedia, BAvatar, BLink,
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BLink,
    vSelect,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      totalUsers: 5,
      isSortDirDesc: false,
      tableColumns: [
        { key: 'username', sortable: true },
        { key: 'pointName', sortable: true },
        { key: 'createDate', sortable: true },
        { key: 'gainedPoint', sortable: true },
      ],
      sortBy: 'id',
    }
  },
  computed: {
    loyaltyHistories() {
      return [
        {
          id: 1,
          username: 'Ozgur Salginci',
          pointName: 'Example Point Name',
          createDate: '20.02.2023',
          gainedPoint: 10,
        },
        {
          id: 10,
          username: 'Ozgur Salginci',
          pointName: 'Example Point Name',
          createDate: '20.02.2023',
          gainedPoint: 10,
        },
        {
          id: 2,
          username: 'Ozgur Salginci',
          pointName: 'Example Point Name',
          createDate: '20.02.2023',
          gainedPoint: 10,
        },
        {
          id: 3,
          username: 'Ozgur Salginci',
          pointName: 'Example Point Name',
          createDate: '20.02.2023',
          gainedPoint: 10,
        },
        {
          id: 4,
          username: 'Ozgur Salginci',
          pointName: 'Example Point Name',
          createDate: '20.02.2023',
          gainedPoint: 10,
        },
      ]
    },
    dataMeta() {
      const localItemsCount = 5
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalUsers,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
